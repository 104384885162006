<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex justify-end py-5">
        <v-btn
          color="accent"
          class="black--text font-weight-bold rounded-xl"
          :disabled="!user.permissions.Event || !user.permissions.Event.update"
          @click="openCouponForm()"
        >
          <span>
            <v-icon> mdi-plus </v-icon>
            Nuevo cupón
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="couponsTable.cols"
          :items="coupons"
          :loading="couponsTable.loading"
          hide-default-footer
          class="elevation-1"
          v-if="coupons.length > 0"
          disable-pagination
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
                class="text-center"
                :class="[
                  $vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-table-row'
                    : '',
                ]"
              >
                <template v-for="colData in couponsTable.cols">
                  <td
                    v-if="colData.value !== 'actions'"
                    :key="colData.value"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <div
                      class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                    >
                      {{ colData.text }}
                    </div>
                    <div
                      :class="
                        $vuetify.breakpoint.xsOnly
                          ? 'v-data-table__mobile-row__cell'
                          : ''
                      "
                    >
                      {{ item[colData.value] }}{{ colData.extraValue }}
                    </div>
                  </td>
                  <td
                    v-else
                    :key="colData.value"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'v-data-table__mobile-row'
                        : ''
                    "
                  >
                    <div
                      class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"
                    >
                      {{ colData.text }}
                    </div>
                    <div
                      :class="
                        $vuetify.breakpoint.xsOnly
                          ? 'v-data-table__mobile-row__cell'
                          : ''
                      "
                    >
                      <v-btn
                        icon
                        color="accent"
                        @click="setCouponToUpdate(item)"
                        :disabled="
                          !user.permissions.Event ||
                          !user.permissions.Event.update
                        "
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="red"
                        @click="setCouponToDelete(item)"
                        :disabled="
                          !user.permissions.Event ||
                          !user.permissions.Event.update
                        "
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-row v-else class="d-flex flex-column align-center">
          <h2 class="my-5">No se han registrado cupones</h2>
          <v-img
            src="@/assets/images/general/no_data_events.svg"
            max-width="300"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="couponDeleteDialog.open" width="500">
      <v-card>
        <v-card-title> Eliminar cupón </v-card-title>
        <v-card-text>
          ¿Está seguro que quiere eliminar el cupón "{{
            couponDeleteDialog.coupon.code
          }}"?
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" justify-md="end" class="my-4">
            <v-btn
              class="mx-2"
              color="blue-grey lighten-3"
              elevation="0"
              rounded
              @click="closeDeleteCoupon()"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="mx-2"
              color="accent"
              elevation="0"
              rounded
              @click="deleteCoupon(couponDeleteDialog.coupon.id)"
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
            >
              Eliminar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="couponForm.open"
      width="700"
      @click:outside="couponForm.open = false"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-form ref="couponForm" v-model="couponForm.valid" lazy-validation>
              <v-row justify="center">
                <v-col cols="12">
                  <p class="headline font-weight-bold text-center mb-0">
                    {{ couponForm.edit ? 'Actualizar cupón' : 'Crear cupón' }}
                  </p>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="couponsData.code"
                    label="Código del cupón"
                    filled
                    rounded
                    :rules="couponValidations.code"
                  />
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="couponsData.percentOff"
                    label="Porcentaje de descuento"
                    filled
                    type="number"
                    rounded
                    :rules="couponValidations.percentage"
                  />
                </v-col>
                <v-col cols="8" v-if="!couponForm.edit">
                  <v-checkbox
                    hide-details
                    @change="toggleLimit"
                    class="mr-4"
                    v-model="acceptBill"
                  >
                    <template v-slot:label>
                      <span class="d-flex align-center pa-0"
                        >Colocar límite al uso de veces que se puede
                        aplicar.</span
                      >
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="8" v-if="openLimit">
                  <v-text-field
                    v-model="couponsData.limit"
                    label="Cantidad de veces que puede ser aplicado"
                    filled
                    rounded
                    type="number"
                    :rules="couponValidations.limit"
                  />
                </v-col>
                <v-col
                  cols="8"
                  class="d-flex align-center"
                  v-if="!couponForm.edit"
                >
                  <v-checkbox
                    hide-details
                    @change="toggleExpirationDate"
                    class="mr-2 pb-4"
                    v-model="expirationDate"
                  >
                    <template v-slot:label>
                      <span class=""
                        >Programar fecha de expiración de cupón.</span
                      >
                    </template>
                  </v-checkbox>
                  <v-tooltip top left offset-y max-width="350px">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" class="ml-0" dark v-on="on">
                        mdi-help-circle
                      </v-icon>
                    </template>
                    <span
                      >Se refiere a la fecha límite en que el cupón seguirá
                      siendo válido, pasando esta fecha, el cupón se inactiva
                      para todos.</span
                    >
                  </v-tooltip>
                </v-col>

                <v-col cols="8" v-if="openExpirationDate">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="initialDateFormatted"
                        label="Fecha de expiración *"
                        persistent-hint
                        readonly
                        v-on="on"
                        required
                        :rules="startDateRules"
                        filled
                        rounded
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="couponsData.endDate"
                      no-title
                      @input="dateMenu = false"
                      :min="todayDate"
                      locale="es"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="8">
                  <h3 class="pb-2">¿En cuál de tus boletos deseas que aplique el cupón?</h3>
                  <ul>
                    <li v-for="ticket in ticketsList" :key="ticket._id">
                      <label class="checkbox-label">
                        <input
                          type="checkbox"
                          v-model="selectedTickets[ticket._id]"
                          class="checkbox-input"
                        />
                        {{ ticket.name }}
                      </label>
                    </li>
                  </ul>
                </v-col>
              </v-row>

              <v-row
                :class="
                  $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
                "
              >
                <v-btn
                  class="mx-2"
                  color="blue-grey lighten-3"
                  elevation="0"
                  rounded
                  @click="closeCouponForm()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  class="mx-2"
                  color="accent"
                  elevation="0"
                  rounded
                  @click="couponForm.edit ? updateCoupon() : createCoupon()"
                  :disabled="!isSaveButtonDisabled"

                >
                  Guardar
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { commit } from "vuex";
import Vue from "vue";
import { validations } from "@/share/validations";
import Endpoints from "@/share/Endpoints";
import { Base64 } from "js-base64";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import path from "path";
import { get } from "http";
import axios from "axios";

export default {
  props: ["eventId"],
  data: () => ({
   
    selectedAtLeastOneTicket: false,
    selectedTicketsState: {},

    totalQuantity: [],
    superSelectedTickets: [],
    dropdownOpen: false,
    selectedTickets: {}, // Inicializar aquí o en el hook created
    ticketsList: [],
    selectedTicket: null,

    ticketsSearchData: {
      eventId: "",
      page: 1,
      perPage: 100,
    },
    acceptBill: false,
    expirationDate: false,
    openExpirationDate: false,
    dateMenu: false,
    openLimit: false,
    coupons: [],
    couponsData: {
      id: "",
      code: "",
      percentOff: null,
      limit: null,
      endDate: "",
      applications: 0,
      status: "ACTIVE",
    },
    endDateFormatted: "",
    couponForm: {
      open: false,
      valid: false,
      edit: false,
    },
    couponDeleteDialog: {
      open: false,
      coupon: {},
    },
    couponValidations: {
      code: validations.generalMValidation({
        name: "código",
        required: true,
      }),
      percentage: validations.numberMValidation({
        name: "porcentaje de descuento",
        required: true,
        min: 1,
        max: 100,
      }),
      limit: validations.numberMValidation({
        name: "limite de uso del cupón",
        required: true,
        min: 1,
      }),
      startDateRules: validations.requiredFValidation(
        "fecha de expiración del cupón"
      ),
    },
    formTitles: {
      CREATE: "Crear cupón",
      UPDATE: "Actualizar cupón",
    },
    couponsTable: {
      loading: false,
      cols: [
        {
          text: "Nombre",
          align: "center",
          value: "code",
        },
        {
          text: "Porcentaje de Descuento",
          align: "center",
          value: "percentOff",
          extraValue: "%",
        },
        {
          text: "Límite de uso",
          align: "center",
          value: "limit",
        },
        {
          text: "Aplicaciones",
          align: "center",
          value: "applications",
        },
        {
          text: "Fecha de expiración",
          align: "center",
          value: "endDate",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
        },
      ],
    },
  }),
  computed: {
    ...mapState("user", ["user"]),
    todayDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);

      const day = ("0" + (date.getDate() + 1)).slice(-2);
      return `${year}-${month}-${day}`;
    },
    initialDateFormatted() {
      return this.formatDate(this.couponsData.endDate.substring(0, 10));
    },
  },

  async created() {
    await this.getEventCoupons();
    this.couponsTable.loading = false;
    console.log(this.couponsTable);
  },
  methods: {
    ...mapActions("ticket", [
      "fetchEventTickets",
      "fetchEventTicketsList",
      "createEventTicket",
      "updateEventTicket",
      "deleteEventTicket",
      "assignItemsToTicket",
      "unassignItemsToTicket",
      "updateDiplomaConfiguration",
    ]),
    ...mapMutations("notification", ["show"]),
    isSaveButtonDisabled() {
    return (
      !this.selectedAtLeastOneTicket ||
      !this.couponForm.valid ||
      !this.user.permissions.Event ||
      !this.user.permissions.Event.update 
    );
  },
    async limitRule() {
      if (this.couponsData.limit > this.totalQuantity) {
        return "puto";
      }
    },

    async openCouponForm() {
      this.couponForm.open = true;
      console.log(this.eventId);
      this.ticketsSearchData.eventId = this.eventId;
      const dataList = await this.fetchEventTicketsList(this.ticketsSearchData);
      this.ticketsList = dataList.tickets;

      console.log(dataList.tickets);
      this.selectedTickets = {};
      this.ticketsList.forEach((ticket) => {
        this.selectedTickets[ticket.id] = false;
        if (ticket.totalQuantity > this.totalQuantity) {
          this.totalQuantity = ticket.totalQuantity;
        }
      });
      console.log(this.totalQuantity);

      //console.log(this.ticketsList);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toggleLimit() {
      this.openLimit = this.acceptBill;
    },
    toggleExpirationDate() {
      this.openExpirationDate = this.expirationDate;
    },

    async getEventCoupons() {
      this.couponsTable.loading = true;
      const response = await this.axios.get(
        Endpoints.couponsId.replace(":id", this.eventId)
      );

      if (response.status === 200) {
        const { coupons } = response.data;

        coupons.forEach((coupon) => {
          if (coupon.endDate) {
            coupon.endDate = coupon.endDate.substring(0, 10);
          } else {
            coupon.endDate = "Sin expiración";
          }
          if (coupon.limit) {
            coupon.limit = coupon.limit;
          } else {
            coupon.limit = "Sin límite";
          }
          if (coupon.applications) {
            coupon.applications = coupon.applications;
          } else {
            coupon.applications = 0;
          }
          /*if (coupon.applications == coupon.limit) {
            coupon.status = "EXPIRED"
          } */
          this.$set(this.selectedTickets, coupon.id, {});

        });


        this.coupons = coupons;
      }

      this.couponsTable.loading = false;
    },
    validateCouponsForm() {
      this.couponForm.valid = !!this.$refs.couponForm.validate();
      this.selectedAtLeastOneTicket = Object.values(this.selectedTickets).some(
      (isChecked) => isChecked
    );
    return this.couponForm.valid && this.selectedAtLeastOneTicket;
    },
    closeCouponForm() {
      this.$refs.couponForm.reset();
      this.couponsData.id = "";
      this.couponForm = {
        edit: false,
        open: false,
        valid: false,
      };
    },
    async createCoupon() {
      try {
        if (this.validateCouponsForm()) {
        const selectedTicketIds = Object.keys(this.selectedTickets).filter(
          (ticketId) => this.selectedTickets[ticketId]
        );
        console.log("selectedTickets:", selectedTicketIds);
        const { id: _, ...couponRest } = this.couponsData;
        const couponsData = {
          coupon: {
            ...couponRest,
            event: this.eventId,
            status: "ACTIVE",
            tickets: selectedTicketIds,
          },
        };
        const response = await this.axios.post(Endpoints.coupons, couponsData);
        if (response.status === 200) {
          this.coupons.push(response.data.coupon)
          this.show({
            color: 'success',
            text: 'El cupón ha sido creado correctamente',
            time: 3500
          })
        };
        this.closeCouponForm();
      }
      await this.getEventCoupons();
    } catch (error) {
      let { message } = error.response.data.error
      console.log(error.response);
      this.show({
        color: 'error',
        text: message,
        time: 3500
      })
    }
    },
    setCouponToUpdate(item) {
      this.couponsData = {
        id: item.id,
        code: item.code,
        percentOff: item.percentOff,
        tickets: item.tickets
      };
      this.ticketsList.forEach((ticket) => {
    this.$set(
      this.selectedTicketsState,
      item.id,
      this.selectedTickets[item.id] || {}
    );
  });
      
      this.couponForm = {
        ...this.couponForm,
        open: true,
        edit: true,
      };
      console.log(this.couponsData)

    },
    async updateCoupon() {
      if (!this.validateCouponsForm() || !this.couponForm.edit) return;
      const { id: _, ...couponRest } = this.couponsData;
      const data = {
        coupon: {
          ...couponRest,
          event: this.eventId,
        },
      };
      const response = await this.axios.put(
        Endpoints.couponsId.replace(":id", this.couponsData.id),
        data
      );
      if (response.status === 200) {
        const { coupon: responseCoupon } = response.data;
        this.coupons = this.coupons.map((coupon) =>
          coupon.id === responseCoupon.id ? responseCoupon : coupon
        );
      }
      this.getEventCoupons();
      this.closeCouponForm();
    },
    closeDeleteCoupon() {
      this.couponDeleteDialog = {
        open: false,
        coupon: {},
      };
    },
    setCouponToDelete(coupon) {
      this.couponDeleteDialog = {
        open: true,
        coupon,
      };
    },
    async deleteCoupon(id) {
      const coupon = {
        event: this.eventId,
        _id: id,
      };
      const response = await this.axios.delete(Endpoints.coupons, {
        data: { coupon },
      });
      if (response.status === 200) {
        this.coupons = this.coupons.filter((coupon) => coupon.id !== id);
      }
      this.closeDeleteCoupon();
    },
  },
};
</script>
<style scoped>
ul {
  list-style-type: none; /* Oculta los bullets de la lista */
  padding-left: 0; /* Elimina el padding izquierdo para los items de la lista */
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-input {
  margin-right: 8px;
  /* ... Resto de los estilos para el checkbox ... */
}

.checkbox-input:checked {
  /* ... Estilos cuando el checkbox está marcado ... */
}
</style>

