<template>
  <v-container fluid>
    <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab href="#sales">Ventas</v-tab>
            <v-tab href="#ticketsAvailable">Accesos disponibles</v-tab>
            <v-tab href="#coupons">Cupones</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="sales">
              <sales :eventId="eventId" />
            </v-tab-item>
            <v-tab-item value="ticketsAvailable">
              <access :eventId="eventId" />
            </v-tab-item>
            <v-tab-item value="coupons">
              <coupons :eventId="eventId" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Sales from './tabs/Sales';
import Access from './tabs/Access';
import Coupons from './tabs/Coupons';

export default {
  components: {
    Sales,
    Access,
    Coupons
  },
  props: ['eventId'],
  data: () => ({
    tab: "sales",
  }),
  methods: {
    ...mapActions('event', ['fetchEventById']),
  },
  async mounted() {
    await this.fetchEventById(this.eventId);
    if(this.$route.params.showTutorial==true){
      this.tab = "ticketsAvailable";
    }
    console.log(this.$route.params.showTutorial);
  }
};
</script>
<style scoped>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}

.theme--light.v-tabs>.v-tabs-bar {
  background-color: transparent !important;
}
</style>